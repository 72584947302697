/* eslint-disable no-plusplus */

"use client";

import React, { useEffect } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

export { arrayMove };

export function mapIndex<T>(items: T[]) {
	return items.map((item: any, index: number) => ({ ...item, index }));
}

let idCounter = 0;

export type Draggable<T> = T & { id: string; index: number };

/**
 * A custom hook that provides draggable functionality for a list of items.
 * @returns {{
 *   data: (T & { id: string; index: string })[],
 *   setData: Function,
 *   handleDrag: Function,
 *   handleRemove: Function,
 *   handleInsert: Function
 * }}
 *   - `data`: The current list of items, each with unique `id` and `index` property.
 *   - `setData`: A function to update the list of items.
 *   - `handleDrag`: A function to handle drag events and reorder items.
 *   - `handleRemove`: A function to remove an item from the list by its ID.
 *   - `handleInsert`: A function to insert a new item at the end of the list.
 */
export function useDraggable<T>(data: (T & { index?: number })[]) {
	const [items, setItems] = React.useState<Draggable<T>[]>([]);

	useEffect(() => {
		const sortedList = [...data].sort(
			(a, b) => (a?.index ?? 0) - (b?.index ?? 0),
		);
		setItems(
			sortedList?.map((item: any, index: number) => ({
				...item,
				id: item?.id || (idCounter++).toString(),
				index,
			})),
		);
	}, [data]);

	const handleRemove = (id: string) => {
		const newList = mapIndex(items.filter((item) => item.id !== id));
		setItems(newList);
		return newList;
	};

	const handleInsert = (item: Omit<T, "index"> & { id?: string }) => {
		const newItem = { ...item, id: item?.id || (idCounter++).toString() };
		const newList = mapIndex([...items, newItem]);
		setItems(newList);
		return newList;
	};

	/**
	 * @returns The updated list of items after reordering, or `undefined` if no update is made.
	 */

	const handleDrag = (event: DragEndEvent) => {
		const { active, over } = event;
		if (active && over && active.id !== over.id) {
			const oldIndex = items.findIndex((item) => item.id === active.id);
			const newIndex = items.findIndex((item) => item.id === over.id);
			const newList = mapIndex(arrayMove(items, oldIndex, newIndex));
			setItems(newList);
			return newList;
		}
		return undefined;
	};

	const setData = (item: (T & { index?: number })[]) => {
		const mappedData = mapIndex(item);
		setItems(mappedData);
		return mappedData;
	};

	return {
		data: items,
		setData,
		handleDrag,
		handleRemove,
		handleInsert,
	};
}

export function getUpdatedIndex<T>(
	event: DragEndEvent,
	items: (T & { id: string })[],
) {
	const { active, over } = event;
	if (active && over && active.id !== over.id) {
		const oldIndex = items.findIndex((item) => item.id === active.id);
		const newIndex = items.findIndex((item) => item.id === over.id);
		return { oldIndex, newIndex };
	}
	return undefined;
}
