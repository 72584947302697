"use client";

import React, { useMemo } from "react";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
	UniqueIdentifier,
} from "@dnd-kit/core";
import {
	restrictToVerticalAxis,
	restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	Row,
	RowData,
	useReactTable,
} from "@tanstack/react-table";
import { IconDragHandler } from "../icon";
import { Table } from "../Table";
import { Skeleton } from "../progress";

interface ColumnMeta<
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TData extends RowData,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TValue,
> {
	align?: "left" | "center" | "right";
	truncate?: boolean;
}

type SortableRow<TData extends { id: string; index: number }> = {
	row: Row<TData>;
	isDisabled?: boolean;
	testId?: string;
};

function SortableItem<TData extends { id: string; index: number }>({
	row,
	isDisabled = false,
	testId,
}: SortableRow<TData>) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: row.original.id, disabled: isDisabled });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const makeTestId = (id: string) => (testId ? `${testId}-${id}` : null);

	return (
		<Table.Row
			ref={setNodeRef}
			style={style}
			// test-id is by index instead of id
			data-testid={makeTestId(`row-${row.original.index}`)}
			className={clsx(isDragging ? "!border-y border-muted z-10" : "")}
		>
			<Table.Column
				{...attributes}
				{...listeners}
				className="w-[30px]"
				style={{
					// eslint-disable-next-line no-nested-ternary
					cursor: isDisabled ? "not-allowed" : isDragging ? "grabbing" : "grab",
				}}
				data-testid={makeTestId(`drag-handler-row-${row.original.index}`)}
			>
				<IconDragHandler
					className={clsx(isDisabled ? "text-neutral-200" : "text-neutral-500")}
				/>
			</Table.Column>
			{/* render row column */}
			{row.getVisibleCells().map((cell) => {
				const { align = "left", truncate = false } =
					(cell.column.columnDef.meta as ColumnMeta<TData, any>) ?? {};
				const columnWidth = cell.column.getSize();

				return (
					<Table.Column
						data-testid={makeTestId(`cell-${cell.column.id}`)}
						style={{
							width: columnWidth !== 150 ? columnWidth : undefined,
						}}
						className={clsx(
							"overflow-hidden",
							truncate && "truncate",
							align === "left" && "text-left",
							align === "center" && "text-center",
							align === "right" && "text-right",
						)}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</Table.Column>
				);
			})}
		</Table.Row>
	);
}

type TableProps<TData extends { id: string; index: number }> = {
	data: TData[];
	columns: ColumnDef<TData, any>[];
	onDrag: (event: DragEndEvent) => void;
	isLoading?: boolean;
	isDisabled?: boolean;
	loadingRowCount?: number;
	"data-testid"?: string;
	emptyState?: React.ReactNode;
};

export function DraggableTable<TData extends { id: string; index: number }>({
	data,
	columns,
	onDrag,
	isLoading = false,
	isDisabled = false,
	loadingRowCount = 3,
	"data-testid": testId,
	emptyState = null,
}: TableProps<TData>) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getRowId: (row) => row.id,
	});

	const dataIds = useMemo<UniqueIdentifier[]>(
		() => data?.map(({ id }) => id),
		[data],
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);
	const rowModel = table.getRowModel();
	const headerGroups = table.getHeaderGroups();
	const makeTestId = (id: string) => (testId ? `${testId}-${id}` : null);

	return (
		<DndContext
			sensors={sensors}
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}
			collisionDetection={closestCenter}
			onDragEnd={onDrag}
		>
			<Table>
				{headerGroups.map((headerGroup) => (
					<Table.Header key={headerGroup.id} data-testid={makeTestId("header")}>
						{/* @ts-ignore */}
						<Table.Column className="w-[30px]" />
						{headerGroup.headers.map((header) => {
							const { align = "left" } =
								(header.column.columnDef.meta as ColumnMeta<TData, any>) ?? {};
							const columnWidth = header.column.getSize();
							const headerContents = header.isPlaceholder
								? null
								: flexRender(
										header.column.columnDef.header,
										header.getContext(),
									);
							return (
								<Table.Column
									style={{
										width: columnWidth !== 150 ? columnWidth : undefined,
									}}
									className={clsx(
										"overflow-hidden",
										align === "left" && "text-left",
										align === "center" && "text-center",
										align === "right" && "text-right",
									)}
								>
									{headerContents}
								</Table.Column>
							);
						})}
					</Table.Header>
				))}
				<Table.Body>
					<SortableContext
						items={dataIds}
						strategy={verticalListSortingStrategy}
					>
						{rowModel.rows.length === 0 ? (
							<div className="flex min-h-[200px] items-center justify-center">
								{emptyState}
							</div>
						) : (
							rowModel.rows.map((row) => (
								<SortableItem
									key={row.id}
									row={row}
									testId={testId}
									isDisabled={isLoading || isDisabled}
								/>
							))
						)}
					</SortableContext>
					{isLoading ? (
						<>
							{Array.from({ length: loadingRowCount }).map((_, index) => (
								<Table.Row
									// eslint-disable-next-line react/no-array-index-key
									key={`loading-${index}`}
								>
									{table.getAllColumns().map((column) => {
										const columnWidth = column.getSize();

										return (
											<Table.Cell
												key={column.id}
												style={{
													width: columnWidth !== 150 ? columnWidth : undefined,
												}}
											>
												<Skeleton className="my-[5px] flex h-[13px] items-center rounded-md" />
											</Table.Cell>
										);
									})}
								</Table.Row>
							))}
						</>
					) : null}
				</Table.Body>
			</Table>
		</DndContext>
	);
}
